import React from 'react'
import Particles from 'react-particles-js';
import Progress from 'components/progress'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import ThemeContext from '../../context'
import './styles.scss'

class Hero extends React.Component {

    static contextType = ThemeContext

    render() {
        return (
            <section id={`${this.props.id}`} className="about" style={{height: this.context.height}}>
                {this.particles()}
                <Row>
                    <Col md={6} className="content">
                        <div className="content-text">
                            <div className="line-text">
                                <h4>About Us</h4>
                            </div>
                            <h3>We are a team of professional UI/UX designers and developers</h3>
                            <div className="separator" />
                            <p>Our team will help you meet a wide spectrum of your needs, both in product development and analytics. Our mission is to help entrepreneurs create their own high-quality product using the latest technology stack. Each line of our code is carefully checked before going to the customer.</p>
                            <div className="social social_icons">
                                <FontAwesomeIcon icon={faGithub} className="social_icon" onClick={() => window.open('https://www.github.com')}/>
                                <FontAwesomeIcon icon={faTwitter} className="social_icon" onClick={() => window.open('https://www.twitter.com')} />
                                <FontAwesomeIcon icon={faYoutube} className="social_icon" onClick={() => window.open('https://www.youtube.com')} />
                                <FontAwesomeIcon icon={faLinkedin} className="social_icon" onClick={() => window.open('https://www.linkedin.com')} />
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className="skills">
                            <div className="line-text">
                                <h4>Our Skills</h4>
                            </div>
                            <div className="skills-container">


                                <Progress name="Swift/SwiftUI" value={90} delay={1100} />
                                <Progress name="Python" value={75} delay={1100} />
                                <Progress name="Front-end React" value={80} delay={1100} />
                                <Progress name="Computer Science" value={70} delay={1100} />
                                <Progress name="Machine Learning" value={70} delay={1100} />
                                <Progress name="Database Engineering" value={75} delay={1100} />
                                <Progress name="Software design pattern" value={80} delay={1100} />
                            </div>
                    </Col>
                </Row>
            </section>
        )
    }

    particles() {
        return (
            <Particles
                className="particles"
                params={{
                    "particles": {
                        "number": {
                            "value": 50,
                            "density": {
                                "enable": false,
                                "value_area": 5000
                            }
                        },
                        "line_linked": {
                            "enable": true,
                            "opacity": .5
                        },
                        "size": {
                            "value": 1
                        },
                    },
                    "retina_detect": true
            }}/>
        )
    }

}

export default Hero
